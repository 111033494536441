import { Card, Flex, Skeleton } from "@radix-ui/themes";
import React, { useCallback, useMemo, useState } from "react";
import { forwardRef } from "react";
import DataGrid, {
  Column,
  ColumnOrColumnGroup,
  DataGridHandle,
  DataGridProps,
  RenderHeaderCellProps,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { useAppearance } from "../../../hooks";
import { Text } from "../Text/Text";

type ColumnWithFilterType = Column<any, any> & { filterType?: "string" | "number" | "date" };
type DataGridV2Props = DataGridProps<any> & {
  loading?: boolean;
  columns: ColumnWithFilterType[];
};

const FilterRenderer = ({
  tabIndex,
  column,
  enabled,
  children,
}: RenderHeaderCellProps<any> & {
  enabled: boolean;
  children: (args: { tabIndex: number }) => React.ReactElement;
}) => {
  return (
    <Flex direction="column" gap="1">
      <Text>{column.name}</Text>
      {enabled ? children({ tabIndex }) : null}
    </Flex>
  );
};

const DataGridV2 = forwardRef<DataGridHandle, DataGridV2Props>(({ loading, ...props }, ref) => {
  const { theme } = useAppearance();
  const [indexedColumns, setIndexedColumns] = useState<ColumnOrColumnGroup<any, any>[]>([...props.columns]);

  const onColMoved = useCallback((sourceColumnKey: string, targetColumnKey: string): void => {
    setIndexedColumns((old) => {
      const newCols = [...old];
      const sourceIndex = newCols.findIndex((col) => {
        if ((col as Column<any, any>).key === undefined) return false;
        return (col as Column<any, any>).key === sourceColumnKey;
      });
      const targetIndex = newCols.findIndex((col) => {
        if ((col as Column<any, any>).key === undefined) return false;
        return (col as Column<any, any>).key === targetColumnKey;
      });
      const [toMove] = newCols.splice(sourceIndex, 1);
      newCols.splice(targetIndex, 0, toMove);
      return newCols;
    });
  }, []);

  // Assign a loadingRows variable in which each row has all the column keys assigned to a loading value
  const loadingRows = Array.from({ length: 10 }).map((_, i) => {
    const row: any = {};
    // biome-ignore lint/complexity/noForEach: no need
    props.columns.forEach((col) => {
      if ((col as Column<any, any>).key !== undefined) {
        const baseWidth = 90; // Base width in pixels
        const randomFactor = Math.floor(Math.random() * 60); // Random additional width between 0-40px
        row[(col as Column<any, any>).key] = <Skeleton height="35%" width={`${baseWidth + randomFactor}px`} />;
      }
    });
    row.expanded = false;
    return row;
  });

  return (
    <Card style={{ padding: 0, contain: "inline-size", flex: 1 }}>
      <DataGrid
        ref={ref}
        {...props}
        rows={loading ? loadingRows : props.rows}
        columns={indexedColumns}
        className={theme === "dark" ? "rdg-dark" : "rdg-light"}
        onColumnsReorder={onColMoved}
      />
    </Card>
  );
});

export { DataGridV2 as DataGrid, FilterRenderer, type Column };
